<template>
  <div>
    <span v-if="user_status == 'true'">
    <CCard>
      <CCardHeader class="">
        <CRow class="p-1">
         <h4 class="color-light ml-2 page_title"><span class="ul_text">RE</span>PORT</h4>
        <span @click="ShowHide()">
          <CImg v-if="show_form" class="ml-2" src="img/logo/minus_icon.png" title="Close Report Form" style="cursor: pointer"></CImg>
          <CImg v-else class="ml-2" src="img/logo/plus_icon.png" title="Open Report Form" style="cursor: pointer"></CImg>
        </span>
        <CDropdown
          ref="dropdown"
          v-if="all_history"
          color="info"
          toggler-text="Load Selection" 
          class="m-2 ml-4"
          size="sm"
        >
        <CDropdownItem v-for="history in all_history" :key="history.name" @click="RunHistory(history)">{{history.name}}</CDropdownItem>
        </CDropdown>
        <CButton color="info" size="sm" class="m-2 ml-2" @click="openModel('Fav_video')">Favorite Videos</CButton>
        </CRow>
        <CCollapse :show="show_form">
          <div class="report_filter">
        <CRow class="mt-3">
          <CCol class="col-md-6 col-12">
            <CCard class="p-2">
              <CRow>
                <CCol>
                <label class="ml-2 mt-2">Date Range</label>
                </CCol>
              </CRow>
              <CRow>
                <CCol>
                  <span class="form-control" @click="()=>date_modal=true" style="cursor: pointer;">
                    {{ changeDateFormateForCalender(startDate)}}   <b>-</b>  {{ changeDateFormateForCalender(endDate) }}
                  </span>
                </CCol>
              <CModal
                title="Select Date Range"
                :show.sync="date_modal"
                :centered="true"
                scrollable
                color="dark"
                
              >
              <date-range-picker 
                :from="date_data.endDate"
                :to="date_data.startdate"
                :future="false"
                :panel="date_data.panel"
                :showControls="false" 
                @select="update"/>
              <template #footer>
                <CButton @click="date_modal = false" color="danger">Close</CButton>
              </template>
              </CModal>
               <!-- <date-time-picker
                  class="col-md-12 col-12"
                  :endDate="endDate"
                  :singleDate="false"
                  :startDate="startDate"
                  @onChange="dateFilter"
                /> -->
              </CRow>
              <CRow>
                <CCol class="mt-2 col-md-8">
                  <label class="ml-2 mt-2">TimeZone</label>
                  <multiselect
                    :limit="2"
                    :clear-on-select="false"
                    :close-on-select="true"
                    track-by="value"
                    label="text"
                    :multiple="false"
                    :options="timezoneopt"
                    :preselect-first="true"
                    :preserve-search="false"
                    placeholder="Pick some"
                    v-model="filter_dash.timezoneopt"
                  >
                  </multiselect>
                  </CCol>
                  <CCol class="mt-2">
                  <label class="ml-2 mt-2">View By</label>
                  <CSelect
                    required
                    :value.sync="filter_dash.granularity"
                    :options="granularity_opt"
                  />
                </CCol>
              </CRow>
              <CRow class="mt-2">
                <CCol class="col-md-12">
                  <div class="group-by">
                    <div class="group-by1">
                  <label class="ml-2 mt-2">Group By</label>
                  <multiselect
                    :limit="2"
                    :clear-on-select="true"
                    :close-on-select="false"
                    track-by="value"
                    label="text"
                    :multiple="true"
                    :options="projectionopt"
                    :preselect-first="false"
                    :preserve-search="false"
                    placeholder="Pick some"
                    v-model="filter_dash.projectionopt"
                  >
                  </multiselect>
                </div>
                </div>
                </CCol>
                
              </CRow>
              <CRow class="pb-2">
                <CCol class="col-md-12">
                  <label class="ml-2 mt-2">Dimension</label>
                  <multiselect
                    :limit="2"
                    :clear-on-select="true"
                    :close-on-select="false"
                    track-by="value"
                    label="text"
                    :multiple="true"
                    :options="metricsopt"
                    :preselect-first="false"
                    :preserve-search="true"
                    placeholder="Pick some"
                    v-model="filter_dash.metricsopt"
                  >
                    <!-- <template slot="selection" slot-scope="{ values, search, isOpen }"><span class="multiselect__single" v-if="filter_dash.metrics.length &amp;&amp; !isOpen">{{ filter_dash.metrics.length }} options selected</span></template> -->
                  </multiselect>
                </CCol>
              </CRow>
            </CCard>
          </CCol>
          <CCol class="col-md-6 col-12">
            <CCard class="p-3">
              <CRow class="col-md-12 col-12">
                <CCol class="text-left">
                  <label>Filter</label>
                </CCol>
                <CCol class="text-right">
                  <CButton class="rounded-pill" variant="outline" color="danger" size="sm" @click="Clone('remove', 0)" v-if="dimfilter || dimfilter_f1 || dimfilter_f2 || dimfilter_f3 || dimfilter_f4">Clear Filters</CButton>
                </CCol>
              </CRow>
              <!-- Filter1 -->
              <CRow class="">
                <CCol class="col-md-4 col-12">
                  <multiselect
                    :limit="2"
                    :clear-on-select="false"
                    :close-on-select="true"
                    :multiple="false"
                    track-by="value"
                    label="text"
                    :options="dimentionopt"
                    :preselect-first="false"
                    :preserve-search="false"
                    @input="getDimention()"
                    placeholder="Pick some"
                    v-model="dimfilter"
                    class="filter1"
                  ></multiselect>
                </CCol>
                <CCol class="col-md-1">
                  <span style="font-size: 1.2rem"><strong>=</strong></span>
                </CCol>
                <CCol class="col-md-4 col-12">
                  <multiselect
                    :limit="2"
                    :clear-on-select="true"
                    :close-on-select="false"
                    :multiple="true"
                    track-by="value"
                    label="text"
                    :options="dimentionopt2"
                    :preselect-first="false"
                    :preserve-search="true"
                    placeholder="Pick some"
                    v-model="dimopt"
                    :taggable="true"
                    @tag="addTag1"
                    class="filter1"
                  >
                  </multiselect>
                </CCol>
                <CCol
                  class="col-md-1 mt-2"
                  v-show="this.filter_fields.filter_1 == false"
                >
                  <span @click="Clone('add', 1)">
                    <CImg
                      src="img/logo/plus_icon.png"
                      title="add"
                      style="cursor: pointer"
                    ></CImg>
                  </span>
                </CCol>
              </CRow>
              <!-- Filter2 -->
              <CRow class="mt-2" v-show="filter_fields.filter_1">
                <CCol class="col-md-4 col-12">
                  <multiselect
                    :limit="2"
                    :clear-on-select="false"
                    :close-on-select="true"
                    :multiple="false"
                    track-by="value"
                    label="text"
                    :options="dimentionopt"
                    :preselect-first="false"
                    :preserve-search="false"
                    @input="getDimention1()"
                    placeholder="Pick some"
                    v-model="dimfilter_f1"
                    class="filter1"
                  ></multiselect>
                </CCol>
                <CCol class="col-md-1">
                  <span style="font-size: 1.2rem"><strong>=</strong></span>
                </CCol>
                <CCol class="col-md-4 col-12">
                  <multiselect
                    :limit="2"
                    :clear-on-select="true"
                    :close-on-select="false"
                    :multiple="true"
                    track-by="value"
                    label="text"
                    :options="dimentionopt2_f1"
                    :preselect-first="false"
                    :preserve-search="true"
                    placeholder="Pick some"
                    v-model="dimopt_f1"
                    :taggable="true"
                    @tag="addTag2"
                    class="filter1"
                  >
                  </multiselect>
                </CCol>
                <CCol
                  class="col-md-1 mt-2"
                  v-show="this.filter_fields.filter_2 == false"
                >
                  <span @click="Clone('add', 2)">
                    <CImg
                      src="img/logo/plus_icon.png"
                      title="add"
                      style="cursor: pointer"
                    ></CImg>
                  </span>
                </CCol>
                <CCol class="col-md-1 mt-2">
                  <span @click="Clone('remove', 1)">
                    <CImg
                      src="img/logo/minus_icon.png"
                      title="add"
                      style="cursor: pointer"
                    ></CImg>
                  </span>
                </CCol>
              </CRow>
              <!-- Filter3 -->
              <CRow class="mt-2" v-show="filter_fields.filter_2">
                <CCol class="col-md-4 col-12">
                  <multiselect
                    :limit="2"
                    :clear-on-select="false"
                    :close-on-select="true"
                    :multiple="false"
                    track-by="value"
                    label="text"
                    :options="dimentionopt"
                    :preselect-first="false"
                    :preserve-search="false"
                    @input="getDimention2()"
                    placeholder="Pick some"
                    v-model="dimfilter_f2"
                    class="filter1"
                  ></multiselect>
                </CCol>
                <CCol class="col-md-1">
                  <span style="font-size: 1.2rem"><strong>=</strong></span>
                </CCol>
                <CCol class="col-md-4 col-12">
                  <multiselect
                    :limit="2"
                    :clear-on-select="true"
                    :close-on-select="false"
                    :multiple="true"
                    track-by="value"
                    label="text"
                    :options="dimentionopt2_f2"
                    :preselect-first="false"
                    :preserve-search="true"
                    placeholder="Pick some"
                    v-model="dimopt_f2"
                    :taggable="true"
                    @tag="addTag3"
                    class="filter1"
                  >
                  </multiselect>
                </CCol>
                <CCol
                  class="col-md-1 mt-2"
                  v-show="this.filter_fields.filter_3 == false"
                >
                  <span @click="Clone('add', 3)">
                    <CImg
                      src="img/logo/plus_icon.png"
                      title="add"
                      style="cursor: pointer"
                    ></CImg>
                  </span>
                </CCol>
                <CCol class="col-md-1 mt-2">
                  <span @click="Clone('remove', 2)">
                    <CImg
                      src="img/logo/minus_icon.png"
                      title="add"
                      style="cursor: pointer"
                    ></CImg>
                  </span>
                </CCol>
              </CRow>
              <!-- Filter4 -->
              <CRow class="mt-2" v-show="filter_fields.filter_3">
                <CCol class="col-md-4 col-12">
                  <multiselect
                    :limit="2"
                    :clear-on-select="false"
                    :close-on-select="true"
                    :multiple="false"
                    track-by="value"
                    label="text"
                    :options="dimentionopt"
                    :preselect-first="false"
                    :preserve-search="false"
                    @input="getDimention3()"
                    placeholder="Pick some"
                    v-model="dimfilter_f3"
                    class="filter1"
                  ></multiselect>
                </CCol>
                <CCol class="col-md-1">
                  <span style="font-size: 1.2rem"><strong>=</strong></span>
                </CCol>
                <CCol class="col-md-4 col-12">
                  <multiselect
                    :limit="2"
                    :clear-on-select="true"
                    :close-on-select="false"
                    :multiple="true"
                    track-by="value"
                    label="text"
                    :options="dimentionopt2_f3"
                    :preselect-first="false"
                    :preserve-search="true"
                    placeholder="Pick some"
                    v-model="dimopt_f3"
                    :taggable="true"
                    @tag="addTag4"
                    class="filter1"
                  >
                  </multiselect>
                </CCol>
                <CCol
                  class="col-md-1 mt-2"
                  v-show="this.filter_fields.filter_4 == false"
                >
                  <span @click="Clone('add', 4)">
                    <CImg
                      src="img/logo/plus_icon.png"
                      cnewofferlass="img-fluid ml-1"
                      title="add"
                      style="cursor: pointer"
                    ></CImg>
                  </span>
                </CCol>
                <CCol class="col-md-1 mt-2">
                  <span @click="Clone('remove', 3)">
                    <CImg
                      src="img/logo/minus_icon.png"
                      title="add"
                      style="cursor: pointer"
                    ></CImg>
                  </span>
                </CCol>
              </CRow>
              <!-- Filter5 -->
              <CRow class="mt-2" v-show="filter_fields.filter_4">
                <CCol class="col-md-4 col-12">
                  <multiselect
                    :limit="2"
                    :clear-on-select="false"
                    :close-on-select="true"
                    :multiple="false"
                    track-by="value"
                    label="text"
                    :options="dimentionopt"
                    :preselect-first="false"
                    :preserve-search="false"
                    @input="getDimention4()"
                    placeholder="Pick some"
                    v-model="dimfilter_f4"
                    class="filter1"
                  ></multiselect>
                </CCol>
                <CCol class="col-md-1">
                  <span style="font-size: 1.2rem"><strong>=</strong></span>
                </CCol>
                <CCol class="col-md-4 col-12">
                  <multiselect
                    :limit="2"
                    :clear-on-select="true"
                    :close-on-select="false"
                    :multiple="true"
                    track-by="value"
                    label="text"
                    :options="dimentionopt2_f4"
                    :preselect-first="false"
                    :preserve-search="true"
                    placeholder="Pick some"
                    v-model="dimopt_f4"
                    :taggable="true"
                    @tag="addTag5"
                    class="filter1"
                  >
                  </multiselect>
                </CCol>

                <CCol class="col-md-1 col-12 mt-2">
                  <span @click="Clone('remove', 4)">
                    <CImg
                      src="img/logo/minus_icon.png"
                      title="add"
                      style="cursor: pointer"
                    ></CImg>
                  </span>
                </CCol>
                <CCol class="col-md-1"> </CCol>
              </CRow>
            </CCard>
          </CCol>
        </CRow>
        <CRow class="">
          <CCol class="col-md-12 text-cente">
            <div class="report-actions">
            <CButton class="col-md-2 col-12" color="success" @click="OnSubmit()"
              >Get Report</CButton
            >
            <vue-csv-downloader
              v-if="items.length > 0"
              class="ml-md-4"
              :data="csvFields"
            >
              <CButton class="col-md-2 col-12 mt-md-0 mt-2" color="info"
                >Download Report</CButton
              >
            </vue-csv-downloader>
            <CButton class="col-md-2 col-12 ml-md-4" color="primary" @click="OpenHistory()"
              >Save Selection</CButton
            >
            </div>
          </CCol>
        </CRow>
      </div>
        </CCollapse>
      </CCardHeader>
      <CCardHeader class="">
        <CRow class="p-1">
         <h4 class="color-light ml-2 page_title"><span class="ul_text">OV</span>ERVIEW</h4>
        <span @click="show_chart = !show_chart">
          <CImg v-if="show_chart" class="ml-2" src="img/logo/minus_icon.png" title="Close Report Form" style="cursor: pointer"></CImg>
          <CImg v-else class="ml-2" src="img/logo/plus_icon.png" title="Open Report Form" style="cursor: pointer"></CImg>
        </span>
        </CRow>
         <CCollapse :show="show_chart">
            <!-- Show Chart -->
            <overview-chart :show_overviewchart="show_chart" :filter_data="filter_dash"></overview-chart>
         </CCollapse>
      </CCardHeader>
      <CCardBody class="text-center">
        <div v-if="loading == true">Loading . . .</div>
        <div v-else>
          <CRow>
            <CCol>
              <CRow class="col-md-12">
                <label>Total Rows : {{ totalRows }}</label>
              </CRow>
            </CCol>
            <CCol class="pb-2">
              <div class="card-header-actions">
                <small class="text-muted">
                  <multiselect
                    :limit="2"
                    :clear-on-select="false"
                    :close-on-select="true"
                    :multiple="false"
                    :options="perPage"
                    :preselect-first="false"
                    :preserve-search="false"
                    @input="checkPage()"
                    placeholder="PerPage"
                    v-model="tableperpage"
                  ></multiselect
                ></small>
              </div>
            </CCol>
          </CRow>
          <!-- <span  v-if="loading">Loading ...</span>
        <span v-else> -->
          <div class="table_div">
          <CDataTable
            :sorterValue.sync="sortDesc"
            :items="items"
            :fields="fields"
            :dark="false"
            :items-per-page="tableperpage"
            :sorter='{ external: true, resetable: true }'
            hover
            striped
            border
            small
            fixed
            column-filter
          >
            <template #date="{ item }">
              <td style="min-width: 15vh;">
                <Tooltip
                :text="pieData1"
                :text2="pieData2"
                :pielabel1="pielabel1[0].data"
                :pielabel2="pielabel2[0].data"
                :showPie="showPie"
                :rowData = "item"
                :camp_id = "camp_list[item.campname]"
                :heatMap_payload="heatMap_payload"
                >
                 <span @mouseover='getTooltipData(item)'
                >
                <div class="card-header-actions float-right">
                  <small class="ml-2" style="color:red;cursor:pointer;"> &#9432;</small>
                </div>
                {{ changeDateFormate(item.date) }}
                 </span>
                 </Tooltip>
              </td>
              <!-- {{item.date}} -->
            </template>
            <!-- column-filter -->
            <template #campname="{ item }"
              >
                <td :title="item.campname" style="white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">
                 <span
                  class="link res_text" style="max-width: 10em;"
                  @click="openModel('CampURL', camp_list[item.campname])"
                >
                  {{ item.campname }}
                  </span>
                  </td>
              </template
            >
            <template #external_id="{ item }">
              <td class="externalid"><span
                  id="openvid"
                  class="link"
                  @click="openModel('Iwitness', item)"
                >
                <div class="external-id-vid">{{ item.external_id }}</div>
            </span></td>
            </template>
            <template #offerid="{ item }"
              ><td>
                <span class="link" @click="getofferData(item.offerid)">{{
                  offer_name[item.offerid]
                }}</span>
              </td></template
            >

            <template #tsid="{ item }"
              ><td>
                <span class="link" @click="getTsData(item.tsid)">
                {{ traffic_source_name[item.tsid] }}
                </span>
                </td></template
            >

            <template #offerrev="{ item }"
              ><td>{{ item.offerrev.toFixed(2) }}</td></template
            >

            <template #camprev="{ item }"
              ><td>{{ item.camprev.toFixed(2) }}</td></template
            >
          <!-- Custom header Text with tooltip  start -->
          <template #browse-header="data">
           <span data-toggle="tooltip" title="Click Counts">Click</span>
          </template>
          <template #render-header="data">
           <span data-toggle="tooltip" title="Count of successful page rendered on the browser">Render</span>
          </template>
          <template #impression-header="data">
           <span data-toggle="tooltip" title="Count of page viewed by the user">Impression</span>
          </template>
          <template #accepted-header="data">
           <span data-toggle="tooltip" title="Count of Page Accepted with clean and relevant traffic.">Valid</span>
          </template>
          <template #discarded-header="data">
           <span data-toggle="tooltip" title="Count of Page Discarded due to irrelevant traffic.">Discarded</span>
          </template>
          <template #rejected-header="data">
           <span data-toggle="tooltip" title="Count of Page Rejection happened due to fraud detection">Invalid</span>
          </template>
          <template #convertedRate-header="data">
           <span data-toggle="tooltip" title="Percentage of Successful Conversions.">Conversion Rate</span>
          </template>
          <template #converted-header="data">
           <span data-toggle="tooltip" title="Count of Successful Conversions">Converted</span>
          </template>
          <template #harmfulApp-header="data">
           <span data-toggle="tooltip" title="Count of visits deriving from Suspicious Pattern sources">Suspicious Pattern</span>
          </template>
          <template #iframeTraffic-header="data">
           <span data-toggle="tooltip" title="Count of traffic deriving from Iframe content">Iframe Traffic</span>
          </template>
          <template #proxyTraffic-header="data">
           <span data-toggle="tooltip" title="Count of traffic proxied via one or more servers">Proxy Traffic</span>
          </template>
          <template #requestTempring-header="data">
           <span data-toggle="tooltip" title="Count of attacks detected based on unauthorized modification of data exchanged between client and server">Request Tempering</span>
          </template>
          <template #dangerIp-header="data">
           <span data-toggle="tooltip" title="Count of Risky IP addresses detected">Danger Ip</span>
          </template>
          <template #adultKeyword-header="data">
           <span data-toggle="tooltip" title="Count of visits detected via Adult keyword sources">Adult Keyword</span>
          </template>
          <template #nonPlayStoreApk-header="data">
           <span data-toggle="tooltip" title="Count of visits detected from Non Playstore App sources.">Non Play Store Application</span>
          </template>
          <template #susDevConvDist-header="data">
           <span data-toggle="tooltip" title="Count of Suspicious Traffic patterns detected">Suspicious Patterns</span>
          </template>
          <template #offerrev-header="data">
           <span data-toggle="tooltip" title="Total Offer Revenue generated on the basis of conversions">Offer Revenue</span>
          </template>
          <template #camprev-header="data">
           <span data-toggle="tooltip" title="Total Campaign Revenue generated">Campaign Revenue</span>
          </template>
          <template #event-header="data">
           <span data-toggle="tooltip" title="Total Count of Event occurred">Event Count</span>
          </template>
          <template #renderRate-header="data">
           <span data-toggle="tooltip" title="Percentage of successful page rendered on the browser">Render Rate</span>
          </template>
          <template #impressionRate-header="data">
           <span data-toggle="tooltip" title="Percentage of Impressions">Impression Rate</span>
          </template>
          <template #rejectedRate-header="data">
           <span data-toggle="tooltip" title="Percentage of Invalid traffic">Invalid Rate</span>
          </template>
          <template #acceptedRate-header="data">
           <span data-toggle="tooltip" title="Percentage of Valid traffic">Valid Rate</span>
          </template>
          <!-- Custom header Text with tooltip  end -->

          <!--start Custom Filter start -->
          <!-- Metrics -->
          <template #browse-filter="{item}">
          <input class="form-control form-control-sm" type="text" v-model="filter.browse" @keyup="customFilter('browse')">
          </template>
          <template #render-filter="{item}">
          <input class="form-control form-control-sm" type="text" v-model="filter.render" @keyup="customFilter('render')">
          </template>
          <template #impression-filter="{item}">
          <input class="form-control form-control-sm" type="text" v-model="filter.impression" @keyup="customFilter('impression')">
          </template>
          <template #accepted-filter="{item}">
          <input class="form-control form-control-sm" type="text" v-model="filter.accepted" @keyup="customFilter('accepted')">
          </template>
          <template #discarded-filter="{item}">
          <input class="form-control form-control-sm" type="text" v-model="filter.discarded" @keyup="customFilter('discarded')">
          </template>
          <template #rejected-filter="{item}">
          <input class="form-control form-control-sm" type="text" v-model="filter.rejected" @keyup="customFilter('rejected')">
          </template>
          <template #converted-filter="{item}">
          <input class="form-control form-control-sm" type="text" v-model="filter.converted" @keyup="customFilter('converted')">
          </template>
          <template #harmfulApp-filter="{item}">
          <input class="form-control form-control-sm" type="text" v-model="filter.harmfulApp" @keyup="customFilter('harmfulApp')">
          </template>
          <template #iframeTraffic-filter="{item}">
          <input class="form-control form-control-sm" type="text" v-model="filter.iframeTraffic" @keyup="customFilter('iframeTraffic')">
          </template>
          <template #proxyTraffic-filter="{item}">
          <input class="form-control form-control-sm" type="text" v-model="filter.proxyTraffic" @keyup="customFilter('proxyTraffic')">
          </template>
          <template #requestTempring-filter="{item}">
          <input class="form-control form-control-sm" type="text" v-model="filter.requestTempring" @keyup="customFilter('requestTempring')">
          </template>
          <template #dangerIp-filter="{item}">
          <input class="form-control form-control-sm" type="text" v-model="filter.dangerIp" @keyup="customFilter('dangerIp')">
          </template>
          <template #adultKeyword-filter="{item}">
          <input class="form-control form-control-sm" type="text" v-model="filter.adultKeyword" @keyup="customFilter('adultKeyword')">
          </template>
          <template #nonPlayStoreApk-filter="{item}">
          <input class="form-control form-control-sm" type="text" v-model="filter.nonPlayStoreApk" @keyup="customFilter('nonPlayStoreApk')">
          </template>
          <template #susDevConvDist-filter="{item}">
          <input class="form-control form-control-sm" type="text" v-model="filter.susDevConvDist" @keyup="customFilter('susDevConvDist')">
          </template>
          <template #offerrev-filter="{item}">
          <input class="form-control form-control-sm" type="text" v-model="filter.offerrev" @keyup="customFilter('offerrev')">
          </template>
          <template #camprev-filter="{item}">
          <input class="form-control form-control-sm" type="text" v-model="filter.camprev" @keyup="customFilter('camprev')">
          </template>
          <template #event-filter="{item}">
          <input class="form-control form-control-sm" type="text" v-model="filter.event" @keyup="customFilter('event')">
          </template>
          <template #renderRate-filter="{item}">
          <input class="form-control form-control-sm" type="text" v-model="filter.renderRate" @keyup="customFilter('renderRate')">
          </template>
          <template #impressionRate-filter="{item}">
          <input class="form-control form-control-sm" type="text" v-model="filter.impressionRate" @keyup="customFilter('impressionRate')">
          </template>
          <template #rejectedRate-filter="{item}">
          <input class="form-control form-control-sm" type="text" v-model="filter.rejectedRate" @keyup="customFilter('rejectedRate')">
          </template>
          <template #acceptedRate-filter="{item}">
          <input class="form-control form-control-sm" type="text" v-model="filter.acceptedRate" @keyup="customFilter('acceptedRate')">
          </template>
          <!-- Projection -->
          <template #campname-filter="{item}">
          <input class="form-control form-control-sm" type="text" v-model="filter.campname" @keyup="customFilter('campname')">
          </template>
          <template #external_id-filter="{item}">
          <input class="form-control form-control-sm" type="text" v-model="filter.external_id" @keyup="customFilter('external_id')">
          </template>
          <template #country-filter="{item}">
          <input class="form-control form-control-sm" type="text" v-model="filter.country" @keyup="customFilter('country')">
          </template>
          <template #offerid-filter="{item}">
          <input class="form-control form-control-sm" type="text" v-model="filter.offerid" @keyup="customFilter('offerid')">
          </template>
          <template #tsid-filter="{item}">
          <input class="form-control form-control-sm" type="text" v-model="filter.tsid" @keyup="customFilter('tsid')">
          </template>
          <template #convertedRate-filter="{item}">
          <input class="form-control form-control-sm" type="text" v-model="filter.convertedRate" @keyup="customFilter('convertedRate')">
          </template>
          <!--start Custom Filter end -->
         </CDataTable>
         <div class="pagination">
            <a href="#" @click="runQuery('','prev')" title="previous">&laquo;</a>
            <a href="#" class="active">{{pageno}}</a>
            <a href="#" v-if="items.length > 0" @click="runQuery('','next')" title="next">&raquo;</a>
          </div>
          </div>
        </div>
      </CCardBody>
    </CCard>

    <!-- Show Model -->
    <CModal
      title="Save Query"
      :show.sync="history"
      size="lg"
      :centered="true"
      scrollable
      color="dark"
    >
      <CCard class="col-md-12 p-3">
        <CRow class="p-2 col-md-12">
          Query Title : <CInput size="sm" class="ml-2" maxlength="20" v-model="history_modal.name" v-on:keyup.enter="SaveToHistory()"></CInput>
          <CButton size="sm" class="ml-md-4 mb-2" color="info" @click="SaveToHistory()"
              >Save Query</CButton
            >
        </CRow>

        <CRow class="col-md-12">
          <CRow class="col-md-12">Query list</CRow>
          <table border>
            <tr>
              <th>Title</th>
              <th>Delete</th>
            </tr>
            <tr v-for="h in all_history" :key="h.name">
              <td>
                <span class="link" @click="()=>{history = false; RunHistory(h)}">{{h.name}}</span>
              </td>
              <td>
                <span class="delete_btn ml-2" @click="deleteQuery(h)">
                <CImg src="img/logo/delelt.png" class="img-fluid ml-1" title="Delete"
                  style="cursor: pointer;"
                  ></CImg></span> 
              </td>
            </tr>
          </table>
        </CRow>
      </CCard>
      <template #footer>
        <CButton @click="history = false" color="danger">Close</CButton>
        <!-- <CButton @click="darkModal = false" color="success">Accept</CButton> -->
      </template>
    </CModal>

    <!-- Show Model -->
    <CModal
      title="Campaign Url"
      :show.sync="camp_url"
      size="lg"
      :centered="true"
      scrollable
      color="dark"
    >
    <url-param :url_data="url_data"></url-param>
      
      <template #footer>
        <CButton @click="camp_url = false" color="danger">Close</CButton>
        <!-- <CButton @click="darkModal = false" color="success">Accept</CButton> -->
      </template>
    </CModal>
    <!-- Show Iwitness Model -->
    <CModal
      title="Inspector"
      :show.sync="show_iwitness"
      size="lg"
      :centered="true"
      scrollable
      color="dark"
    >
    <iwitness :iwitness_data="iwitness_data"></iwitness>
      
      <template #footer>
        <CButton @click="show_iwitness = false" color="danger">Close</CButton>
        <!-- <CButton @click="darkModal = false" color="success">Accept</CButton> -->
      </template>
    </CModal>

    <!-- Show Favorite video Model -->
    <CModal
      title="Favorite Video"
      :show.sync="show_fav_video"
      size="lg"
      :centered="true"
      scrollable
      color="dark"
    >
    <fav-video :show_fav_video="show_fav_video"></fav-video>
      <template #footer>
        <CButton @click="show_fav_video = false" color="danger">Close</CButton>
        <!-- <CButton @click="darkModal = false" color="success">Accept</CButton> -->
      </template>
    </CModal>

    </span>
    <span v-else>
      <CCard class="col-md-12">
         <h4 style="color:red;">Your Account is not activated</h4>
        <label>please contact to Manager</label>
      </CCard>
    </span>
    <vue-tour :name="'report'"></vue-tour>
  </div>
</template>


<script>
import moment from 'moment'
import DashboardService from "./../services/dashboardController";
import OfferService from "./../services/offerControlService";
import CampaignService from "./../services/campaignControlService";
import TsService from "./../services/trafficsourceControllservice";
import SettingService from "./../services/settingControlService";
import Config from "./../services/config";
import TimeZone from "./../services/timezones";
import Multiselect from "vue-multiselect";
import "@lazy-copilot/datetimepicker/dist/datetimepicker.css";
import { DateTimePicker } from "@lazy-copilot/datetimepicker";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import VueCsvDownloader from 'vue-csv-downloader';
import UrlParam from "../components/UrlParam.vue";
import Tooltip from "../components/ToolTip.vue";
import OverviewChart from '../components/OverviewChart.vue';
import Iwitness from '../components/Iwitness.vue';
import FavVideo from '../components/Favoritevideo.vue';
import VueTour from "../components/vueTour.vue";
var timezonelist = TimeZone
export default {
  name: "Dashboard",
  components: {
    Multiselect,
    DateTimePicker,
    VueCsvDownloader,
    UrlParam,
    TimeZone,
    Tooltip,
    OverviewChart,
    Iwitness,FavVideo,
    VueTour
  },
  computed:{
      pieData1(){
        return[
              {
          backgroundColor: ['#4FB941', '#3C69ED', '#FF0000', '#000000'],
          data: [0, 0, 0, 0]
        }
          ]
      },
      pieData2(){
        return[
              {
          label:"Security Checks",
          backgroundColor: [
            '#EC7063',
             '#AF7AC5',
             '#5499C7',
             '#1ABC9C',
             '#1ABC9C',
             '#F39C12',
             '#DC7633',
             '#5D6D7E',
          ],
          data: [0,0,0,0,0,0,0,0]
        }
          ]
      },
      pielabel1(){
        return[
          {
            data:["normal","sus","high","discard"]
          }
        ]
      },
      pielabel2(){
        return[
          {
            data:["harmfulApp","iframeTraffic","proxyTraffic","requestTempring","dangerIp","adultKeyword","nonPlayStoreApk","susDevConvDist"]
          }
        ]
      }
  },
  data() {
    return {
      pageno:1,
      show_fav_video:false,
      show_iwitness:false,
      iwitness_data:[],
      history_opt:[{ value: "", text: "" },],
      all_history:[],
      history_modal:{name:'',history:[]},
      history:false,
      showPie:false,
      date_data:{startdate:null,enddate:null,panel:null},
      date_modal:false,
      sortDesc:{},
      all_data:[],
      filter: {},
      totalRows:0,
      filter_fields:{filter_1 :false,filter_2:false,filter_3:false,filter_4:false},
      tableperpage:1000,
      perPage:[20,50,100,500,1000,10000],
      isactive_revrisk:false,
      pageOptions: [10, 15],
      items: [],
      click_trigger: {},
      user: "",
      user_status: false,
      useremail: "",
      camp_list: [],
      offer_name: [],
      traffic_source_name: [],
      timezoneopt:timezonelist.map((t)=>{return {value:t.tzCode,text:t.label}}),
      filter_dash: {
        edate: new Date(),
        sdate: new Date(new Date().getTime() - 30 * 24 * 60 * 60 * 1000),
        dim: {},
        granularity: "daily",
        projection: [],
        metrics: [],
        useremail: "",
        projectionopt:[],
        metricsopt:[
        { value: "browse", text: "Click" },
        { value: "render", text: "Render" },
        { value: "impression", text: "Impression" },
        { value: "accepted", text: "Valid" },
        { value: "discarded", text: "Discarded" },
        { value: "rejected", text: "Invalid" },
        { value: "converted", text: "Converted" }
      ],
        sortBy:'',
        sortAescDir:false,
        timezoneopt:{ text: '(GMT+01:00) London', value: 'Europe/London' }
      },
      startDate: new Date(),
      endDate: new Date(),
      click_risk: { blockedClick: null },
      conversion_risk: {},
      fields: [],
      dimfilter: "",
      dimfilter_f1 :"",
      dimfilter_f2 :"",
      dimfilter_f3 :"",
      dimfilter_f4 :"",
      projectionopt: [
        { value: "campname", text: "Campaign" },
        { value: "offerid", text: "Offer" },
        { value: "tsid", text: "TrafficSource" },
        { value: "country", text: "Country" },
        { value: "browser", text: "Browser" },
        { value: "os", text: "Operating System" },
        { value: "devicetype", text: "Device Type" },
        { value: "networktype", text: "Network Type" },
        // { value: "frausLvlForUser", text: "Fraud Level" }, // remove key as recieved changes from Team
        { value: "external_id", text: "External Id" },
        { value: "carrier", text: "Carrier" },
        { value: "languages", text: "Languages" },
        { value: "timezone", text: "TimeZone" },
        { value: "make", text: "Make" },
        { value: "model", text: "Model" },
        { value: "eventMessage", text: "Event Message" },
        { value: "qp_sub1", text: "Sub1" },
        { value: "qp_sub2", text: "Sub2" },
        { value: "qp_sub3", text: "Sub3" },
        { value: "qp_sub4", text: "Sub4" },
        { value: "qp_sub5", text: "Sub5" },
        { value: "qp_sub6", text: "Sub6" },
        { value: "qp_sub7", text: "Sub7" },
        { value: "qp_sub8", text: "Sub8" },
        { value: "qp_sub9", text: "Sub9" },
        { value: "qp_sub10", text: "Sub10" },
        { value: "qp_sub11", text: "Sub11" },
        { value: "qp_sub12", text: "Sub12" },
      ],
      metricsopt: [
        { value: "browse", text: "Click" },
        { value: "render", text: "Render" },
        { value: "impression", text: "Impression" },
        { value: "accepted", text: "Valid" },
        { value: "discarded", text: "Discarded" },
        { value: "rejected", text: "Invalid" },
        { value: "convertedRate", text: "Conversion Rate" },
        { value: "converted", text: "Converted" },
        { value: "harmfulApp", text: "Suspicious Pattern" },
        { value: "iframeTraffic", text: "Iframe Traffic" },
        { value: "proxyTraffic", text: "Proxy Traffic" },
        { value: "requestTempring", text: "Request Tempering" },
        // { value: "deviceAnomalies", text: "Device Anomalies" },
        { value: "dangerIp", text: "Danger IP" },
        { value: "adultKeyword", text: "Adult Keyword" },
        { value: "nonPlayStoreApk", text: "Non PlayStore Application" },
        { value: "susDevConvDist", text: "Suspicious Patterns" },
        { value: "offerrev", text: "Offer Revenue" },
        { value: "camprev", text: "Campaign Revenue" },
        { value: "event", text: "Event Count" },
        { value: "renderRate", text: "Render Rate" },
        { value: "impressionRate", text: "Impression Rate" },
        { value: "rejectedRate", text: "Invalid Rate" },
        { value: "acceptedRate", text: "Valid Rate" },
      ],
      dimentionopt: [
        { value: "campname", text: "Campaign" },
        // { value: "camp_id", text: "Impression" },
        // { value: "userid", text: "User ID" },
        { value: "offerid", text: "Offer" },
        { value: "tsid", text: "Traffic Source" },
        { value: "country", text: "Country" },
        { value: "carrier", text: "Carrier" },
        { value: "os", text: "Os" },
        { value: "browser", text: "Browser" },
        { value: "devicetype", text: "Device Type" },
        { value: "networktype", text: "Network Type" },
        //  { value: "frausLvlForUser", text: "Fraud Level" }, // remove key as recieved changes from Team
        { value: "external_id", text: "External Id" },
        { value: "languages", text: "Languages" },
        { value: "timezone", text: "TimeZone" },
        { value: "make", text: "Make" },
        { value: "model", text: "Model" },
        { value: "converted", text: "Converted" },
        { value: "eventMessage", text: "Event Message" },
        { value: "qp_sub1", text: "Sub1" },
        { value: "qp_sub2", text: "Sub2" },
        { value: "qp_sub3", text: "Sub3" },
        { value: "qp_sub4", text: "Sub4" },
        { value: "qp_sub5", text: "Sub5" },
        { value: "qp_sub6", text: "Sub6" },
        { value: "qp_sub7", text: "Sub7" },
        { value: "qp_sub8", text: "Sub8" },
        { value: "qp_sub9", text: "Sub9" },
        { value: "qp_sub10", text: "Sub10" },
        { value: "qp_sub11", text: "Sub11" },
        { value: "qp_sub12", text: "Sub12" },
      ],
      granularity_opt: ["daily", "hourly","monthly","yearly","realtime"],
      dimentionopt2: [],
      dimentionopt2_f1: [],
      dimentionopt2_f2: [],
      dimentionopt2_f3: [],
      dimentionopt2_f4: [],
      dimopt: [],
      dimopt_f1: [],
      dimopt_f2: [],
      dimopt_f3: [],
      dimopt_f4: [],
      url: "",
      zeroms_url: "",
      camp_url: false,
      url_param: {sub1: "sub1",sub2: "sub2",sub3: "sub3",sub4: "sub4",sub5: "sub5",sub6: "sub6",sub7: "sub7",
                  sub8: "sub8",sub9: "sub9",sub10: "sub10",sub11: "sub11",sub12: "sub12"},
      zeroms_url_param: {sub1: "sub1",sub2: "sub2",sub3: "sub3",sub4: "sub4",sub5: "sub5",sub6: "sub6",sub7: "sub7",
                        sub8: "sub8",sub9: "sub9",sub10: "sub10",sub11: "sub11",sub12: "sub12"},
      sdk_url_param: {sub1: "sub1",sub2: "sub2",sub3: "sub3",sub4: "sub4",sub5: "sub5",sub6: "sub6",sub7: "sub7",
                      sub8: "sub8",sub9: "sub9",sub10: "sub10",sub11: "sub11",sub12: "sub12"},
      collapseurl: false,
      collapsesdk: false,
      collapsezeroms: false,
      useremail:'',
      csvFields: [],
      change_date : false,
      loading:false,
      projectionopt_val:[],
      metricsopt_val:[],
      filter_count:0,
      sdk_url:'',
      sdk_data:'',
      url_data:{url:'',zeroms_url:'',sdk_data:'',sdk_url:''},
      show_form:true,
      show_chart:false,
      heatMap_payload:{},
      history_store_length:19
    };
  },
  watch: {
    sortDesc: function (val) {
      this.runQuery(val)
    },
  },
  async mounted() {
    if(window.localStorage.getItem('guide_tool') == 'true'){
      var classes = ['.create-offer','.traffic-source','.create-campaign','.sdk-menu','.report-menu','.campaign-list']
        classes.map((t)=> {
          var ele = document.querySelector(t);
          if(ele){ele.classList.remove('focus_guide');}
        })
        this.$tours['sidebar'].stop()
        this.$tours['report'].start()
      }
    if(window.localStorage.getItem("user") == 'manager'){
      this.$router.push("/manager/userlist");
    }
    else{
    if (
      !window.localStorage.getItem("user") ||
      window.localStorage.getItem("user") == "null"
    ) {
      // alert("Please Enter User Name and Password");
      this.$router.push("/pages/login");
    } else {
      if(localStorage.getItem("d_table") != null){
      this.tableperpage = parseInt(localStorage.getItem("d_table"));
      }
      // this.show_form = localStorage.getItem("showform") == 'true' ? true : false;
      this.user = window.localStorage.getItem("user");
      this.user_status = window.localStorage.getItem("status");
      this.useremail = window.localStorage.getItem("email");
      this.filter_dash.useremail = this.useremail;

      if(!localStorage.getItem("filter_dash1")) {
        this.filter_dash.projection = ['campname']
        this.projectionopt_val = [{ value:"campname",text:"Campaign" }]
      }
      
      if(this.user_status == 'true'){
        this.dateFilter({startDate:new Date(this.startDate),endDate:new Date(this.endDate)});
      if(new Date(this.filter_dash.edate).getDate() != new Date().getDate()){
          this.startDate = new Date();
          this.endDate = new Date();
          this.dateFilter({startDate:new Date(this.startDate),endDate:new Date(this.endDate)});
        }
      if(localStorage.getItem("filter_dash1")){
        this.CheckLocalStorage();
      }
      if(this.$root.$data.smartsearch && this.$root.$data.smartsearch !=''){
        this.filter_dash.projectionopt = this.$root.$data.smartsearch.projection;
        this.filter_dash.metricsopt = this.$root.$data.smartsearch.metrics;
        this.dimfilter = this.$root.$data.smartsearch.dimfilter;
        this.dimopt = this.$root.$data.smartsearch.dimopt;
        this.startDate = this.$root.$data.smartsearch.sdate;
        this.endDate  = this.$root.$data.smartsearch.edate;
        this.filter_dash.sdate = this.startDate
        this.filter_dash.edate = this.endDate
  
      }
      if(JSON.parse(localStorage.getItem("history"))){
      this.all_history = JSON.parse(localStorage.getItem("history"));
      }
      await this.runQuery();
      
      try {
        let response = await DashboardService.camplist({
          useremail: this.useremail,
        });
        if (response.result) this.camp_list = response.message.campaign;
        this.offer_name = response.message.offer;
        this.traffic_source_name = response.message.trafficsource;
      } catch (e) {
        console.error("Error in getting camplist");
        console.log(e);
      }
      }
    }}
    window.addEventListener('keydown', this.onKeyDown);
  },
  beforeDestroy() {
    window.removeEventListener('keydown', this.onKeyDown);
  },
  methods: {
   async getReportOptions(){
    let response = await SettingService.getSubValue();
      if(response.result == true){
        let subMapData = response.data.subValueMappping;
        if(subMapData && typeof subMapData === 'object' && Object.keys(subMapData).length > 0) 
        {
          Object.keys(subMapData).forEach((key) => {
            this.projectionopt.map(item => {
              if(item.value == 'qp_'+key){
                item.text = subMapData[key];
              }
            })
            this.dimentionopt.map(item => {
              if(item.value == 'qp_'+key){
                item.text = subMapData[key];
              }
            })
          });
        }
        var bProjection = response.data.bProjection;
        if(bProjection.length>0){
          // delete option from projection if in projection
          bProjection.map( item=> {
            this.projectionopt = this.projectionopt.filter(function (el) {
              return el.value !== item;
            })
            this.dimentionopt = this.dimentionopt.filter(function (el) {
              return el.value !== item;
            })
          })
        }
        let bMetrics = response.data.bMetrics;
        if(bMetrics.length>0){
          // delete option from metrics if in metrics
          bMetrics.map( item=> {
            this.metricsopt = this.metricsopt.filter(function (el) {
              return el.value !== item;
            })
          })
        }
      }
      if(this.dimfilter !=''){
        this.dimfilter = this.dimentionopt.filter(item => item.value == this.dimfilter.value)[0]
      }
      if(this.dimfilter_f1 !='' && this.dimfilter_f1 != null){
        this.dimfilter_f1 = this.dimentionopt.filter(item => item.value == this.dimfilter_f1.value)[0]
      }
      if(this.dimfilter_f2 !='' && this.dimfilter_f2 != null){
        this.dimfilter_f2 = this.dimentionopt.filter(item => item.value == this.dimfilter_f2.value)[0]
      }
      if(this.dimfilter_f3 !='' && this.dimfilter_f3 != null){
        this.dimfilter_f3 = this.dimentionopt.filter(item => item.value == this.dimfilter_f3.value)[0]
      }
      if(this.dimfilter_f4 !='' && this.dimfilter_f4 != null){
        this.dimfilter_f4 = this.dimentionopt.filter(item => item.value == this.dimfilter_f4.value)[0]
      }
    },
    filterFilterDash(){
      this.filter_dash.projectionopt = this.projectionopt.filter(item_b => this.filter_dash.projectionopt.some(item_a => item_a.value == item_b.value));
      this.filter_dash.projection = this.filter_dash.projection.filter(item_b => this.projectionopt.some(item_a => item_a.value == item_b));
      this.filter_dash.metricsopt = this.metricsopt.filter(item_b => this.filter_dash.metricsopt.some(item_a => item_a.value == item_b.value));
      this.filter_dash.metrics = this.filter_dash.metrics.filter(item_b => this.metricsopt.some(item_a => item_a.value == item_b));
      localStorage.setItem("filter_dash1", JSON.stringify(this.filter_dash)); 
    },
    deleteQuery(del){
      if(confirm("Are you sure you want to delete this query?")){
      this.all_history = this.all_history.filter(function(item) {
          return item !== del
      })
      window.localStorage.setItem('history', JSON.stringify(this.all_history))
    }
    },
    RunHistory(data){
      this.Clone('remove',1);this.Clone('remove',2);this.Clone('remove',3);this.Clone('remove',4);
      this.filter_dash = {}
      this.dimopt = this.dimopt_f1 = this.dimopt_f2 = this.dimopt_f3 = this.dimopt_f4 = []
      this.dimentionopt2 = this.dimentionopt2_f1 = this.dimentionopt2_f2 = this.dimentionopt2_f3 = this.dimentionopt2_f4  =  []
      this.filter_dash = data.history
      var filters = data.filters;
      this.dimfilter = filters[0].dimfilter
        if(this.dimfilter != ''){ this.getDimention(); this.dimopt = filters[0].dimopt}
        this.dimfilter_f1 = filters[1].dimfilter1
        if(this.dimfilter_f1 != ''){ this.Clone('add',1);
        this.getDimention1();
        this.dimopt_f1 = filters[1].dimopt1 }
        this.dimfilter_f2 = filters[2].dimfilter2
        if(this.dimfilter_f2 != ''){ this.Clone('add',2); this.getDimention2();
        this.dimopt_f2 = filters[2].dimopt2 }
        this.dimfilter_f3 = filters[3].dimfilter3
        if(this.dimfilter_f3 != ''){ this.Clone('add',3); this.getDimention3();
        this.dimopt_f3 = filters[3].dimopt3 }
        this.dimfilter_f4 = filters[4].dimfilter4
        if(this.dimfilter_f4 != ''){ this.Clone('add',4); this.getDimention4();
        this.dimopt_f4 = filters[4].dimopt4 }
        this.dateFilter({startDate:new Date(data.history.sdate),endDate:new Date(data.history.edate)});
     this.runQuery()
    },
    SaveToHistory(){
      if(this.history_modal.name){
      var filters = [
      {dimfilter:this.dimfilter,dimopt:this.dimopt},
      {dimfilter1:this.dimfilter_f1,dimopt1:this.dimopt_f1},
      {dimfilter2:this.dimfilter_f2,dimopt2:this.dimopt_f2},
      {dimfilter3:this.dimfilter_f3,dimopt3:this.dimopt_f3},
      {dimfilter4:this.dimfilter_f4,dimopt4:this.dimopt_f4}
      ]
      this.history_modal.history = this.filter_dash
      this.history_modal.filters = filters
      var history = []
      if(JSON.parse(localStorage.getItem("history"))){
      history = JSON.parse(localStorage.getItem("history"));
      }
      if(history.length <= this.history_store_length){
      history.push(this.history_modal)
      window.localStorage.setItem('history', JSON.stringify(history))
      this.all_history = history
      Swal.fire({title: "Success!",text: "history stored",icon: "success",showConfirmButton: false,position: 'top-end',timer: 1000});
      this.history_modal.name = ''
      this.all_history = JSON.parse(localStorage.getItem("history"));
      }
      else{
      Swal.fire({title: "Error!",text: "history limit exceeded",icon: "error",confirmButtonText: "Ok",position: 'top-end',showConfirmButton: false,timer: 1000});
      }
    }else{
      Swal.fire({title: "Error!",text: "Please enter Query title",icon: "error",confirmButtonText: "Ok",position: 'top-end',showConfirmButton: false,timer: 1000});
    }
    },
    OpenHistory(){
      this.history = true
      this.history_modal = {}
      this.all_history = JSON.parse(localStorage.getItem("history"));
    },
    async getTooltipData(item){
      this.showPie = false
      var filter = []
      if(this.dimfilter){
      if(this.dimfilter.value){
        filter.push(this.dimfilter)
      }
      if(this.dimfilter_f1.value){
        filter.push(this.dimfilter_f1)
      }
      if(this.dimfilter_f2.value){
        filter.push(this.dimfilter_f2)
      }
      if(this.dimfilter_f3.value){
        filter.push(this.dimfilter_f3)
      }
      if(this.dimfilter_f4.value){
        filter.push(this.dimfilter_f4)
      }}
      
      this.filter_dash.projectionopt.map(item => {
        filter.push(item)
      })
      let uniq = Object.values(filter.reduce((acc,cur)=>Object.assign(acc,{[cur.value]:cur}),{}))

      var data = []
        for(let i of uniq){
        data.push({
          name:i.value,
          value:item[i.value]
      })
        }
      let payload = {
            "date":item.date,
            "viewby":this.filter_dash.granularity,
            "groupby":data,
            "timezone":this.filter_dash.timezoneopt.value
            }
      this.heatMap_payload = payload

      let response = await DashboardService.fraudcount(payload);
       let a = response.message.graphData
       let a1 = response.message.checksData
        let key=Object.keys(a)
        let key1=Object.keys(a1)
        let b = []; 
        let b1 = []; 
        for(let i of key) {
        b.push(a[i])
        }
        for(let j of key1) {
        b1.push(a1[j])
        }
      this.pielabel1[0].data = key;
      this.pieData1[0].data = b;
      this.pielabel2[0].data = key1;
      this.pieData2[0].data = b1;
      this.showPie = true


    },
    changeDateFormateForCalender(d){
      let dateToFormate
      if(typeof d != "string") {
        dateToFormate = new Date(d);
      } else {
        let dd = d.split("T")[0]
        dateToFormate = new Date(dd);
      } 
     return dateToFormate.toLocaleDateString("en-US", {day: "numeric",year: "numeric",month: "long"})
    },
    changeDateFormate(d){
      var p = new Date(d);
      if(this.filter_dash.granularity == "monthly") {
         return moment.utc(d).format('MMM YYYY')
        
      }else if(this.filter_dash.granularity == "hourly") {
        return moment.utc(d).format('MMMM D, YYYY, HH:mm')
      } else if(this.filter_dash.granularity == "realtime"){
        return moment.utc(d).format('MMMM D, YYYY, HH:mm:ss')
      }else if(this.filter_dash.granularity == "yearly"){
        return moment.utc(d).format('YYYY')
      } else {
        return moment.utc(d).format('MMMM D, YYYY')
      }
     
    },
    update(values) {
      this.date_data.startdate = values.from
      this.date_data.enddate = values.to
      this.date_data.panel = values.panel
      this.dateFilter({startDate:new Date(values.from),endDate:new Date(values.to)})
      this.date_modal = false;
    },
    ShowHide(){
      this.show_form = !this.show_form
      localStorage.setItem('showform',this.show_form)
    },
    decodeHTMLEntities(text) {
    var entities = [
        ['amp', '&'],
        ['apos', '\''],
        ['#x27', '\''],
        ['#x2F', '/'],
        ['#39', '\''],
        ['#47', '/'],
        ['lt', '<'],
        ['gt', '>'],
        ['nbsp', ' '],
        ['quot', '"']
    ];

    for (var i = 0, max = entities.length; i < max; ++i) 
        text = text.replace(new RegExp('&'+entities[i][0]+';', 'g'), entities[i][1]);

    return text;
    },
    customFilter() {
      // remove blank keys from filter
      for (let key in this.filter) {
        if (this.filter.hasOwnProperty(key)) {
          if (this.filter[key] === "") {
            delete this.filter[key];
          }
        }
      }
      let f_data = [];
      this.items = this.all_data;
      for (let key in this.filter) {
        if (this.filter.hasOwnProperty(key)) {
          const value = this.filter[key];
          let search = value.toString().toLowerCase();
          let field = key;
          let logic = search[0];
      
          if (['>', '<', '='].includes(logic)) {
            console.log('in logic')
            search = search.substring(1);
            f_data = this.items.filter(row => {
              const fieldValue = row[field].toString().toLowerCase();
              const searchValue = search.toLowerCase();
              switch (logic) {
                case '>':
                  return Number(fieldValue) > Number(searchValue);
                case '<':
                  return Number(fieldValue) < Number(searchValue);
                case '=':
                  return fieldValue === searchValue;
              }
            });
          }
          else if(field == 'offerid'){
            const offerIds = Object.keys(this.offer_name).filter(key => this.offer_name[key].toLowerCase().includes(search));
            f_data = this.items.filter(item => offerIds.some(offerId => item.offerid.includes(offerId)));
          }
          else if(field == 'tsid'){
            const tsIds = Object.keys(this.traffic_source_name).filter(key => this.traffic_source_name[key].toLowerCase().includes(search));
            f_data = this.items.filter(item => tsIds.some(tsId => item.tsid.includes(tsId)));
          }
          else {
            f_data = this.items.filter(row =>{
              if (row[field] != null) {
                return row[field].toString().toLowerCase().includes(search.toLowerCase())
              }
              return false;
            }
            );
          }
        }
        this.items = f_data;
      }
    },
    Clone(txt,count){
      if(txt == 'add'){
      switch(count){
        case 1 : this.filter_fields.filter_1 = true
        break;
        case 2 : this.filter_fields.filter_2 = true
        break;
        case 3 : this.filter_fields.filter_3 = true
        break;
        case 4 : this.filter_fields.filter_4 = true
        break;
      }
      }
      if(txt == 'remove'){
        switch(count){
        case 0 : this.filter_fields.filter_1 = this.filter_fields.filter_2 = this.filter_fields.filter_3 = this.filter_fields.filter_4 = false
                 this.dimfilter = this.dimfilter_f1 = this.dimfilter_f2 = this.dimfilter_f3 = this.dimfilter_f4 = ""
                 this.dimopt = this.dimopt_f1 = this.dimopt_f2 = this.dimopt_f3 = this.dimopt_f4 = []
                 this.dimentionopt2 = this.dimentionopt2_f1 = this.dimentionopt2_f2 = this.dimentionopt2_f3 = this.dimentionopt2_f4 = []
        case 1 : this.filter_fields.filter_1 = false
                 this.dimfilter_f1 = ""
                 this.dimopt_f1 = []
                 this.dimentionopt2_f1 = []
        break;
        case 2 : this.filter_fields.filter_2 = false
                 this.dimfilter_f2 = ""
                 this.dimopt_f2 = []
                 this.dimentionopt2_f2 = []
        break;
        case 3 : this.filter_fields.filter_3 = false
                 this.dimfilter_f3 = ""
                 this.dimopt_f3 = []
                 this.dimentionopt2_f3 = []
        break;
        case 4 : this.filter_fields.filter_4 = false
                 this.dimfilter_f4 = ""
                 this.dimopt_f4 = []
                 this.dimentionopt2_f4 = []
        break;
      }
      }
    },
    getLabel(key){
      return labels[key]
    },
    checkPage(){
      localStorage.setItem("d_table", this.tableperpage);
      this.pageno=1
      this.runQuery();
    },
    async openModel(txt, id) {
      switch (txt) {
        case "CampURL":
          this.url = Config.campurl + id;
          let res = await CampaignService.getTid({campid: id});
          var tid = res.message.camp_eid
          this.zeroms_url = Config.zeromsurl + tid;
          this.sdk_url = Config.sdkurl + id;
          var sdk_data = "&lt;script src=&quot;"+this.sdk_url+"&quot; defer&gt;&lt;/script&gt;\n"+
                          "&lt;script&gt;\n"+
                            "document.addEventListener(&quot;rendered&quot;, function (e) {\n"+
                                "var event_id=e.detail;\n"+    
                            "/* your code to do xhr request to your own api, your own api will call fraudAvoid api to validate the user. */\n"+
                            "})&lt;/script&gt;";
          this.sdk_data = this.decodeHTMLEntities(sdk_data)
          this.url_data.url = this.url
          this.url_data.zeroms_url = this.zeroms_url
          this.url_data.sdk_data = this.sdk_data
          this.url_data.sdk_url = this.sdk_url
          this.camp_url = true;
          break;
          case "Iwitness":
            this.iwitness_data = id
            // if(window.localStorage.getItem("iwitness") == 'true'){
            this.show_iwitness = true
            // }
            // else{//   Swal.fire({title: "Error!", text: "I-Witness Feature not Activated. Please contact to manager.", icon: "error",
            //   confirmButtonText: "Ok",position: 'top-end',showConfirmButton: false,timer: 5000
            // });
            // }
            break;
          case "Fav_video":
            this.show_fav_video = true
      }
    },
    async getofferData(id) {
      try {
        var data = { useremail: this.useremail, offerid: id };
        let response = await OfferService.get(data);
        this.$root.$data.offer = response.message[0];
        this.$root.$data.edit = true;
        this.$router.push("/offer/newoffer");
        // this.editoffer = true;
      } catch (e) {
        // Swal.fire({
        //   title: "Error!",
        //   text: "connection error",
        //   icon: "error",
        //   confirmButtonText: "Ok",position: 'top-end',showConfirmButton: false,timer: 1000
        // });
        // this.errorNotify("Error", "connection error", "danger");
        //   this.$router.push("Login");
      }
    },
    async getTsData(id) {
      try {
        var data = { useremail: this.useremail, tsid: id };
        let response = await TsService.tsget(data);
        this.$root.$data.traffic = response.message[0];
        this.$root.$data.edit = true;
        this.$router.push("/ts/newts");
        // this.editoffer = true;
      } catch (e) {
        // Swal.fire({
        //   title: "Error!",
        //   text: "connection error",
        //   icon: "error",
        //   confirmButtonText: "Ok",position: 'top-end',showConfirmButton: false,timer: 1000
        // });
        // this.errorNotify("Error", "connection error", "danger");
        //   this.$router.push("Login");
      }
    },
    addTag1(newTag) { this.dimopt.push({value:newTag,text:newTag}); },
    addTag2(newTag) { this.dimopt_f1.push({value:newTag,text:newTag}); },
    addTag3(newTag) { this.dimopt_f2.push({value:newTag,text:newTag}); },
    addTag4(newTag) { this.dimopt_f3.push({value:newTag,text:newTag}); },
    addTag5(newTag) { this.dimopt_f4.push({value:newTag,text:newTag}); },
    dateFilter(data) {
      this.filter_dash.sdate = new Date(
        data.startDate.getTime() - data.startDate.getTimezoneOffset() * 60000
      ).toISOString();
      this.filter_dash.edate = new Date(
        data.endDate.getTime() - data.startDate.getTimezoneOffset() * 60000
      ).toISOString();
      this.startDate = data.startDate
      this.endDate = data.endDate
      // this.filter_dash.sdate = (new Date(data.startDate)).toISOString();
      // this.filter_dash.edate = (new Date(data.endDate)).toISOString()
    },
    OnSubmit(){
      this.filter_dash.sortBy = ''
      this.filter_dash.sortAescDir = false
      this.filter_dash.projection = [];
      this.pageno = 1;
      this.runQuery()
    },
    runQuery: async function(sort, pagination) {
      await this.getReportOptions();
      this.filterFilterDash();
      if(pagination == 'next'){this.pageno ++}
          if(pagination == 'prev'){
            if(this.pageno != 1){
            this.pageno --
            }
          }
      // this.startDate = new Date(this.startDate)
      // this.endDate = new Date(this.endDate)
      this.filter = {}
      this.show_chart = false;
      this.loading = true
      this.change_date = false
      if(this.filter_dash.granularity == "hourly"){
        this.change_date = true
      }
      try {
        this.filter_dash.dim = {}
        this.pushTodim();
        this.filter_dash.projectionopt.map((m) => {
          this.filter_dash.projection.push(m.value);
          this.projectionopt_val.push({value:m.value, text: m.text})
        });
        this.filter_dash.metrics = []
        this.filter_dash.metricsopt.map((m) => {
          this.filter_dash.metrics.push(m.value);
          this.metricsopt_val.push({value:m.value, text: m.text})
        });
        var response
        if(sort){
          this.filter_dash.sortBy = sort.column
          this.filter_dash.sortAescDir = sort.asc
        }

        this.filter_dash.projection =  [...new Set(this.filter_dash.projection)];
        this.filter_dash.page = this.pageno
        this.filter_dash.limit = this.tableperpage
        response = await DashboardService.getdata(this.filter_dash);
        this.storetoLocalStorage()
        if(response.result){
        this.items = [];
        this.items = response.message.tableData;
        this.all_data = response.message.tableData;
        this.sortOptions();
        this.metricsopt_val=[]
        this.projectionopt_val = []
        this.totalRows = this.items.length;
        }
        else{
          Swal.fire({title: "Error!",text: response.message,icon: "error",confirmButtonText: "Close",position: 'top-end',showConfirmButton: false,timer: 2000})
        }
        this.loading = false
      } catch (e) {
        console.log("Error in getting dashboard data:");
        console.log(e);
      }
    },
    storetoLocalStorage(){
      window.localStorage.setItem('filter_dash1', JSON.stringify(this.filter_dash))
      var filters = [
      {dimfilter:this.dimfilter,dimopt:this.dimopt},
      {dimfilter1:this.dimfilter_f1,dimopt1:this.dimopt_f1},
      {dimfilter2:this.dimfilter_f2,dimopt2:this.dimopt_f2},
      {dimfilter3:this.dimfilter_f3,dimopt3:this.dimopt_f3},
      {dimfilter4:this.dimfilter_f4,dimopt4:this.dimopt_f4}
      ]
      window.localStorage.setItem('filters', JSON.stringify(filters))

    },
    CheckLocalStorage(){
        this.filter_dash = JSON.parse(localStorage.getItem("filter_dash1"));
        this.startDate = new Date(this.filter_dash.sdate);
        this.endDate = new Date(this.filter_dash.edate);
        this.endDate = new Date(this.endDate.setDate(this.endDate.getDate() - 1))
        var filters = JSON.parse(localStorage.getItem("filters"));
        this.dimfilter = filters[0].dimfilter
        
        if(this.dimfilter != ''){ this.getDimention(); this.dimopt = filters[0].dimopt}
        this.dimfilter_f1 = filters[1].dimfilter1
        
        if(this.dimfilter_f1 != ''){ this.Clone('add',1);
        this.getDimention1();
        this.dimopt_f1 = filters[1].dimopt1 }
        this.dimfilter_f2 = filters[2].dimfilter2
        
        if(this.dimfilter_f2 != ''){ this.Clone('add',2); this.getDimention2();
        this.dimopt_f2 = filters[2].dimopt2 }
        this.dimfilter_f3 = filters[3].dimfilter3
        
        if(this.dimfilter_f3 != ''){ this.Clone('add',3); this.getDimention3();
        this.dimopt_f3 = filters[3].dimopt3 }
        this.dimfilter_f4 = filters[4].dimfilter4
        
        if(this.dimfilter_f4 != ''){ this.Clone('add',4); this.getDimention4();
        this.dimopt_f4 = filters[4].dimopt4 }
    },
    pushTodim() {
      // let cake = this.filter_dash.dim;
      if(this.dimfilter != '' && this.dimfilter != null){
      if (this.dimfilter.value) {
        this.filter_dash.dim[this.dimfilter.value] = this.dimopt.map(r=>r.value);
      }
      if (this.dimfilter_f1 != '' && this.dimfilter_f1 != null) {
        this.filter_dash.dim[this.dimfilter_f1.value] = this.dimopt_f1.map(r=>r.value);
      }
      if (this.dimfilter_f2 != '' && this.dimfilter_f2 != null) {
        this.filter_dash.dim[this.dimfilter_f2.value] = this.dimopt_f2.map(r=>r.value);
      }
      if (this.dimfilter_f3 && this.dimfilter_f3 != null) {
        this.filter_dash.dim[this.dimfilter_f3.value] = this.dimopt_f3.map(r=>r.value);
      }
      if (this.dimfilter_f4 && this.dimfilter_f4 != null) {
        this.filter_dash.dim[this.dimfilter_f4.value] = this.dimopt_f4.map(r=>r.value);
      }
    }
    },
    capitalize(s)
    {
      let r = s[0].toUpperCase() + s.slice(1)
        return r.replace(/([a-z])([A-Z])/g, '$1 $2');
    },
    sortOptions() {
      // Create an options list from our fields
      let dim = Object.keys(this.filter_dash.dim).map((f) => {
       var d = this.dimentionopt.filter(t => t.value  == f)
       if(d.length > 0){
        return { label: d[0].text, key: d[0].value, sortable: true ,_classes: 'dim'};
       }
      });
      
      // let projection = this.filter_dash.projectionopt.map((f) => {
      //   return { label: this.capitalize(f), key: f, sortable: true };
      // });
      let projection = this.projectionopt_val.map((f) => {
        return { label: this.capitalize(f.text), key: f.value, sortable: true , _classes: 'projection'};
      });
      // let metrics = this.filter_dash.metrics.map((f) => {
      //   return { label: this.capitalize(f), key: f, sortable: true , _classes: 'metrics'};
      // });
      let metrics = this.metricsopt_val.map((f) => {
        return { label: this.capitalize(f.text), key: f.value, sortable: true , _classes: 'metrics'};
      });
      this.fields = []
      this.fields = [
        { label: "Date", key: "date", sortable: true },
        ...dim,
        ...projection,
        ...metrics,
      ];
      this.fields= this.fields.filter(item => typeof item !== 'undefined');
      // this.csvFields = this.fields.map((e) => e.key);
      this.csvFields = this.replaceKeys(this.items, this.fields);
      let b = [];
      for(let e of this.fields) if( b.findIndex(f => f.key == e.key) == -1) b.push(e)
      this.fields = b
      // let p = []
      // var f =  [...new Set(this.fields.map(item => p.push({label: item.label , key : item.key})))];
      return;
    },
    async getDimention() {
      try {
        this.dimentionopt2 = []
        this.dimopt = [];
        let data = { dim: this.dimfilter.value };
        // console.log(data);
        if (data.dim) {
        }
        let response = await DashboardService.getdim(data);
        // console.log(response)
       
        if (response.result) {
          // this.dimentionopt2 = [{value:'Loading...', key:'loading'}]
          if(this.dimfilter.value == 'offerid'){
            for(let [key, value] of Object.entries(this.offer_name)){
              this.dimentionopt2.push({value: key,text:value})
            }
          // var d = { useremail: this.useremail, offerid: response.message };
          // let res = await OfferService.get(d);
          // this.dimentionopt2 = res.message.map((p) =>{return  {value:p._id , text:p.offername} })
          }
          else if(this.dimfilter.value == 'tsid'){
            for(let [key, value] of Object.entries(this.traffic_source_name)){
              this.dimentionopt2.push({value: key,text:value})
            }
          }
          else {
            this.dimentionopt2 = response.message.map((r)=>{return {value:r, text:r}});
            }
        } else {
          this.dimentionopt2 = [];
        }
      } catch (error) {
        // this.errorNotify("Error", "connection error", "danger");
      }
    },
    async getDimention1() {
      try {
        this.dimentionopt2_f1 = []
        this.dimopt_f1 = [];
        let data = { dim: this.dimfilter_f1.value };
        // console.log(data);
        if (data.dim) {
        }
        let response = await DashboardService.getdim(data);
        // console.log(response)
       
        if (response.result) {
          // this.dimentionopt2 = [{value:'Loading...', key:'loading'}]
          if(this.dimfilter_f1.value == 'offerid'){
            for(let [key, value] of Object.entries(this.offer_name)){
              this.dimentionopt2_f1.push({value: key,text:value})
            }
          // var d = { useremail: this.useremail, offerid: response.message };
          // let res = await OfferService.get(d);
          // this.dimentionopt2 = res.message.map((p) =>{return  {value:p._id , text:p.offername} })
          }
          else if(this.dimfilter_f1.value == 'tsid'){
            for(let [key, value] of Object.entries(this.traffic_source_name)){
              this.dimentionopt2_f1.push({value: key,text:value})
            }
          }
          else {
            this.dimentionopt2_f1 = response.message.map((r)=>{return {value:r, text:r}});
            }
        } else {
          this.dimentionopt2_f1 = [];
        }
      } catch (error) {
        // this.errorNotify("Error", "connection error", "danger");
      }
    },
    async getDimention2() {
      try {
        this.dimentionopt2_f2 = []
        this.dimopt_f2 = [];
        let data = { dim: this.dimfilter_f2.value };
        // console.log(data);
        if (data.dim) {
        }
        let response = await DashboardService.getdim(data);
        // console.log(response)
       
        if (response.result) {
          // this.dimentionopt2 = [{value:'Loading...', key:'loading'}]
          if(this.dimfilter_f2.value == 'offerid'){
            for(let [key, value] of Object.entries(this.offer_name)){
              this.dimentionopt2_f2.push({value: key,text:value})
            }
          // var d = { useremail: this.useremail, offerid: response.message };
          // let res = await OfferService.get(d);
          // this.dimentionopt2 = res.message.map((p) =>{return  {value:p._id , text:p.offername} })
          }
          else if(this.dimfilter_f2.value == 'tsid'){
            for(let [key, value] of Object.entries(this.traffic_source_name)){
              this.dimentionopt2_f2.push({value: key,text:value})
            }
          }
          else {
            this.dimentionopt2_f2 = response.message.map((r)=>{return {value:r, text:r}});
            }
        } else {
          this.dimentionopt2_f2 = [];
        }
      } catch (error) {
        // this.errorNotify("Error", "connection error", "danger");
      }
    },
    async getDimention3() {
      try {
        this.dimentionopt2_f3 = []
        this.dimopt_f3 = [];
        let data = { dim: this.dimfilter_f3.value };
        // console.log(data);
        if (data.dim) {
        }
        let response = await DashboardService.getdim(data);
        // console.log(response)
       
        if (response.result) {
          // this.dimentionopt2 = [{value:'Loading...', key:'loading'}]
          if(this.dimfilter_f3.value == 'offerid'){
            for(let [key, value] of Object.entries(this.offer_name)){
              this.dimentionopt2_f3.push({value: key,text:value})
            }
          // var d = { useremail: this.useremail, offerid: response.message };
          // let res = await OfferService.get(d);
          // this.dimentionopt2 = res.message.map((p) =>{return  {value:p._id , text:p.offername} })
          }
          else if(this.dimfilter_f3.value == 'tsid'){
            for(let [key, value] of Object.entries(this.traffic_source_name)){
              this.dimentionopt2_f3.push({value: key,text:value})
            }
          }
          else {
            this.dimentionopt2_f3 = response.message.map((r)=>{return {value:r, text:r}});
            }
        } else {
          this.dimentionopt2_f3 = [];
        }
      } catch (error) {
        // this.errorNotify("Error", "connection error", "danger");
      }
    },
    async getDimention4() {
      try {
        this.dimentionopt2_f4 = []
        this.dimopt_f4 = [];
        let data = { dim: this.dimfilter_f4.value };
        // console.log(data);
        if (data.dim) {
        }
        let response = await DashboardService.getdim(data);
        // console.log(response)
       
        if (response.result) {
          // this.dimentionopt2 = [{value:'Loading...', key:'loading'}]
          if(this.dimfilter_f4.value == 'offerid'){
            for(let [key, value] of Object.entries(this.offer_name)){
              this.dimentionopt2_f4.push({value: key,text:value})
            }
          // var d = { useremail: this.useremail, offerid: response.message };
          // let res = await OfferService.get(d);
          // this.dimentionopt2 = res.message.map((p) =>{return  {value:p._id , text:p.offername} })
          }
          else if(this.dimfilter_f4.value == 'tsid'){
            for(let [key, value] of Object.entries(this.traffic_source_name)){
              this.dimentionopt2_f4.push({value: key,text:value})
            }
          }
          else {
            this.dimentionopt2_f4 = response.message.map((r)=>{return {value:r, text:r}});
            }
        } else {
          this.dimentionopt2_f4 = [];
        }
      } catch (error) {
        // this.errorNotify("Error", "connection error", "danger");
      }
    },
    color(value) {
      let $color;
      if (value <= 25) {
        $color = "info";
      } else if (value > 25 && value <= 50) {
        $color = "success";
      } else if (value > 50 && value <= 75) {
        $color = "warning";
      } else if (value > 75 && value <= 100) {
        $color = "danger";
      }
      return $color;
    },
    onKeyDown(event) {
      if (event.keyCode === 27) { // escape key
        this.$refs.dropdown.hide();
      }
    },
    replaceKeys(data, mapping) {
        return data.map(obj => {
            const newObj = {};
            mapping.forEach(map => {
                newObj[map.label] = obj[map.key];
            });
            return newObj;
        });
    },
  },
};
</script>
<style scoped>
.link {
  color: blue;
}
.link:hover {
  cursor: pointer;
  text-decoration: underline;
}
.delete_btn{
  color: red;
}
.delete_btn:hover {
  cursor: pointer;
  text-decoration: underline;
}
.f_button{
  margin-top: 35px;
}
.pagination {
  display: block;
}

.pagination a {
  color: black;
  float: left;
  padding: 8px 16px;
  text-decoration: none;
  transition: background-color .3s;
  border: 1px solid #ddd;
  font-size: 22px;
}

.pagination a.active {
  background-color: #4CAF50;
  color: white;
  border: 1px solid #4CAF50;
}

.pagination a:hover:not(.active) {background-color: #ddd;}
</style>
<style>
.table_div{
  overflow: auto; height: 500px;
}
.table_div thead { position: sticky !important; top: 0 !important; z-index: 1 !important; }
table  { border-collapse: collapse; width: 100%; }
th, td { padding: 8px 16px; }
th     { background:#eee; }
.table-responsive{
  overflow: unset !important;
}
.externalid{
  max-width: 500px; 
}
.dim{
  /* max-width: 150px; */
  /* white-space: nowrap; */
  overflow: hidden;
  text-overflow: ellipsis; 
}
.metrics{
  /* max-width: 110px;
  white-space: nowrap; */
  overflow: hidden;
  text-overflow: ellipsis; 
}
.projection{
  /* max-width: 100px;
  white-space: nowrap; */
  overflow: hidden;
  text-overflow: ellipsis; 
}
.filter1:focus-within .multiselect__content-wrapper{
  width: 300px !important;
}
</style>
